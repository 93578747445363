import Dashboard from "@material-ui/icons/Dashboard";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard";

import NewPickSheet from "views/NewPickSheet/NewPickSheet";
import AdminPortal from "views/AdminPortal/AdminPortal";
import OrderManagement from "views/OrderManagement/OrderManagement";
import { Shipping } from "views/Shipping/Shipping";
import { Consignments } from "views/Consignments/Consignments";
import { QuickQuote } from "views/QuickQuote/QuickQuote";

const dashboardRoutes = [
  {
    path: "/newPickSheet",
    name: "Pick Sheet",
    icon: Dashboard,
    component: NewPickSheet,
    layout: "/authenticated",
  },
  {
    path: "/orderManagement",
    name: "Order Management",
    icon: Dashboard,
    component: OrderManagement,
    layout: "/authenticated",
  },
  {
    path: "/shipping",
    name: "Shipping",
    icon: Dashboard,
    component: Shipping,
    layout: "/authenticated",
  },
  {
    path: "/consignments",
    name: "Consignments",
    icon: Dashboard,
    component: Consignments,
    layout: "/authenticated",
  },
  {
    path: "/quickQuote",
    name: "QuickQuote",
    icon: Dashboard,
    component: QuickQuote,
    layout: "/authenticated",
  },
  {
    path: "/adminPortal",
    name: "Admin Portal",
    icon: Dashboard,
    component: AdminPortal,
    layout: "/authenticated",
  },
  {
    path: "/dashboard",
    name: "EG TRADE v2.0",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/authenticated",
  },
];

export default dashboardRoutes;
